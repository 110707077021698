<template>
  <div class="blog-comment-container">
    <MessageArea title="标题列表" :subTitle="`(${data.total})`" :list="data.rows" 
    :isListLoading="isLoading" @submit="handleSubmit" />
  </div>
</template>

<script>
import MessageArea from "../../components/MessageArea";
import fetchData from "../../mixins/fetchData";
import {getComments,postComment} from "../../api/blog.js";
export default {
   mixins: [fetchData({total:0,row:[]})],
   components:{
     MessageArea,
   },
   data(){
     return {
        page:1,
        limit:10,
     }
   },
   created(){
    this.$bus.$on("mainScroll",this.handleScroll)
   },
   destroyed(){
     this.$bus.$off("mainScroll",this.handleScroll)
   },
   computed:{
     hasMore(){
      //判断总长度是否大于现在要的长度
       return this.data.total>this.data.rows.length
     }
   },
   methods:{
    handleScroll(dom){
          if(this.isLoading || !dom){
            return
          }
          const range = 100;
          const de = Math.abs(dom.scrollTop+dom.clientHeight-dom.scrollHeight)
          if(de<range){ 
            this.fetchMore();
          }
    },
    async fetchData(){
        return await getComments(
            this.$route.params.id,
            this.page,
            this.limit,
        );
    },
    async fetchMore(){
      //计算属性不是方法
       if(!this.hasMore){
        return
       }
       this.isLoading=true;
       this.page++;
       const resp= await this.fetchData();
       this.data.total=resp.total;
       this.data.rows=this.data.rows.concat(resp.rows);
       this.isLoading = false;
    },
    async handleSubmit(formData, callback) {
      const resp = await postComment({
        blogId: this.$route.params.id,
        ...formData,
      });
      //把响应结果加到数组开头
      this.data.rows.unshift(resp);
      this.data.total++;
      callback("评论成功"); // 告诉子组件，我这边处理完了，你继续
    },
   }

}
</script>

<style scoped lang="less">
.blog-comment-container {
  margin: 30px 0;
}
</style>