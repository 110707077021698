import { Store, install } from "vuex";
import Vue from "vue";
// import banner from "./banner";
import setting from "./setting";
import about from "./about";
import project from "./project";

// if (!window.Vuex) {
//   install(Vue);
// }

const store =new Store({
   modules:{
   //  banner,
     setting,
     about,
     project,
   },
   strict: true,
})
// Vue.use(store)

export default store;