import request from "./request"

export async function getBlogs(page=1,limit=10,categoryid = -1){
  return request.get("/api/blog",{
    //axios里参数由params配置
    params:{
       page,
       limit,
       categoryid,
    }
  })
}

//获取博客分类
export async function getBlogTypes(){
    return await request.get("/api/blogtype")
}
  
export async function getBlogCategories() {
  return await request.get("/api/blogtype");
}

export async function getBlog(id) {
  return await request.get(`/api/blog/${id}`);
}

//提交评论
 
export async function postComment(commentInfo) {
  return await request.post(`/api/comment`, commentInfo);
}

export async function getComments(blogid, page = 1, limit = 10) {
  return await request.get("/api/comment", {
    //这里指的是地址栏?后面的参数
    params: {
      blogid,
      page,
      limit,
    },
  });
}