import getComponentRootDom from "./getComponentRootDom";
import Icon from "@/components/Icon.vue"
import styles from "./showMessage.module.less"
//tpye这里只分为info warm error success
export default function(options = {}){
    const content = options.content || "";
    const type = options.type || "info";
    const duration = options.duration || 2000;
    const container = options.container || document.body;
//创建消息元素
    const div =document.createElement("div");
    const iconDom =getComponentRootDom(Icon,{
            type,
    })
    div.innerHTML=`<span class="${styles.icon}">${iconDom.outerHTML}</span><div>${content}</div>`;
    div.className =`${styles.message} ${styles[`message-${type}`]}`;
    if(options.container){
        if(getComputedStyle(container).position==="static"){
            container.style.position = "relative";
        }
    }
    container.appendChild(div);
    div.clientHeight; // 导致reflow

    // 回归到正常位置
    div.style.opacity = 1;
    div.style.transform = `translate(-50%, -50%)`;
  
    // 等一段时间，消失
    setTimeout(() => {
      div.style.opacity = 0;
      div.style.transform = `translate(-50%, -50%) translateY(-25px)`;
      div.addEventListener(
        "transitionend",
        function() {
          div.remove();
          // 运行回调函数
          options.callback && options.callback();
        },
        { once: true }
      );
    }, duration);
}