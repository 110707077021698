<template>
<div class="layout-container">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="main">
       <slot name="default"></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>   
</div>
</template>
/*令左、右的flex: 0 0 auto即不能拉伸也不能压缩,中间的区域flex为1 1.*/
 
<script>
export default {
 
}
</script>

<style scoped lang="less">
.layout-container {
  width: 100%;
  height: 100%;
  display: flex;
  .left,.right {
    flex: 0 0 auto;
    overflow: hidden;
  }
  .main {
    flex: 1 1 auto;
    overflow: hidden;
  }
}
</style>

