<!--在这里递归后组件的span元素点击也会产生click事件，并通过处理函数向其父元素提交select事件，递归的父元素处理不了，因而得向再上一级的父元素提交事件
，故而仍然沿用同一处理函数-->
<template>
<ul class="right-list-container">
    <li v-for="(item,i) in list"  :key="i">
        <span @click="handleClick(item)" :class="{active:item.isSelect}">
            {{item.name}}
        </span>
        <span 
          v-if="item.aside" 
          @click="handleClick(item)" 
          class="aside"
          :class="{ active: item.isSelect }"
        >
             {{item.aside}}
        </span>
        <RightList :list="item.children" @select="handleClick"/>
    </li>
</ul>
</template>


<script>
//组件无名则默认使用name的名字
export default {
   name:"RightList",
   props:{
    list:{
        type:Array,
        default:()=>[],
    }
   },
   methods:{
       handleClick(item){
          this.$emit("select",item)
        
       }
   }
}
</script>

<style scoped lang="less">
@import "~@/styles/var.less";
.right-list-container{
    list-style: none;
    padding: 0;
    .right-list-container{
      margin-left:1em;      
    }
    li{
        min-height: 40px;
        line-height: 40px;
        cursor: pointer;
        font-size: 14px;
        .active{
           color:@warn;
           font-weight:bold;
        }
    }

} 
.aside {
  font-size: 12px;
  margin-left: 1em;
  color: @gray;
} 
</style>