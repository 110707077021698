<template>
  <div class="pager-container" v-if="pageNumber>=0">
    <a @click="handleClick(1)" :class="{disable:current ===1}">|&lt;&lt;</a>
    <a @click="handleClick(current-1)" :class="{disable:current ===1}">&lt;&lt;</a>
    <a @click="handleClick(n)" v-for="(n,i) in numbers" :key="i" :class="{active: n === current}">{{n}}</a>
    <a @click="handleClick(current+1)" :class="{disable:current === pageNumber}">&gt;&gt;</a>
    <a @click="handleClick(pageNumber)" :class="{disable:current === pageNumber}">&gt;&gt;|</a>
  </div>
</template>

<script>
export default {
   props:{
    current:{
     type:Number,
     default:1,
    },
    total:{
      type:Number,
      default:0,
    },
    limit:{
       type:Number,
       default:10,
    },
    visableNumber:{
       type:Number,
       default:10,
    }
   },
   computed:{
        pageNumber(){
          return Math.ceil(this.total / this.limit);
        },
        visableMin(){
            let min= this.current - Math.floor(this.visableNumber/2);
            if(min<1){
               min = 1;
            }
            return min;
        },
        visableMax(){
            let max=this.visableMin + this.visableNumber-1;
            if(max>this.pageNumber){
                max = this.pageNumber;
            }
            return max;
        },
        numbers(){
           let nums = [];
           for(let i =this.visableMin;i<=this.visableMax;i++){
               nums.push(i);
           }
           return nums;
        },
    },
    methods:{
        handleClick(e){
          this.$emit("pageChange",e)
        }
    }
}
</script>
  
<style lang="less" scoped>
@import "../styles/var.less";
.pager-container{
    display:flex;
    justify-content: center;
    margin: 20px 10px;
    a{
        color: @primary;
        margin: 0 6px;
        cursor: pointer;
        &.disable{
         color:@lightWords;
         cursor: not-allowed;
        }
        &.active{
          color: @words;
          font-weight: bold;
          cursor:text ;
        }
    };
    
}   
</style>