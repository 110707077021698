import Home from "@/views/home";
import About from "@/views/about";
import Blog from "@/views/blog";
import Project from "@/views/project";
import Message from "@/views/message";
import BlogDetail from "@/views/blog/Detail";
import NotFound from "@/views/NotFound.vue";

export default [
  { name: "Home", path: "/", component: Home ,meta:{title:"首页"}},
  { name: "About", path: "/about", component: About ,meta:{title:"关于我"}},
  { name: "Blog", path: "/blog", component: Blog ,meta:{title:"文章"}},
  { name: "CategoryBlog",path:"/blog/cate/:categoryId ",component: Blog,meta:{title:"文章"}},
  { name: "BlogDetail", path: "/blog/:id", component: BlogDetail ,meta:{title:"文章内容"}},
  { name: "Project", path: "/project", component: Project ,meta:{title:"项目和效果"}},
  { name: "Message", path: "/message", component: Message ,meta:{title:"留言板"}},
  {
    name: "NotFound",
    path: "*",
    component: NotFound,
  },
];


//用于页面分包
// export default [
//   {
//     name: "Home",
//     path: "/",
//     component: () => import(/* webpackChunkName: "home" */ "@/views/Home"),
//     meta: {
//       title: "首页",
//     },
//   },
//   {
//     name: "About",
//     path: "/about",
//     component: () => import(/* webpackChunkName: "about" */ "@/views/About"),
//     meta: {
//       title: "关于我",
//     },
//   },
//   {
//     name: "Blog",
//     path: "/article",
//     component: () => import(/* webpackChunkName: "blog" */ "@/views/Blog"),
//     meta: {
//       title: "文章",
//     },
//   },
//   {
//     name: "CategoryBlog",
//     path: "/article/cate/:categoryId",
//     component: () => import(/* webpackChunkName: "blog" */ "@/views/Blog"),
//     meta: {
//       title: "文章",
//     },
//   },
//   {
//     name: "BlogDetail",
//     path: "/article/:id",
//     component: () =>
//       import(/* webpackChunkName: "blogdetail" */ "@/views/Blog/Detail"),
//     meta: {
//       title: "文章详情",
//     },
//   },
//   {
//     name: "Project",
//     path: "/project",
//     component: () =>
//       import(/* webpackChunkName: "project" */ "@/views/Project"),
//     meta: {
//       title: "项目&效果",
//     },
//   },
//   {
//     name: "Message",
//     path: "/message",
//     component: () =>
//       import(/* webpackChunkName: "message" */ "@/views/Message"),
//     meta: {
//       title: "留言板",
//     },
//   },
// ];


//异步加载组件的应用
//import "nprogress/nprogress.css";
// import { start, done, configure } from "nprogress";

// configure({
//   trickleSpeed: 20,
//   showSpinner: false,
// });

// function delay(duration) {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve();
//     }, duration);
//   });
// }

// function getPageComponent(pageCompResolver) {
//   return async () => {
//     start();
//     if (process.env.NODE_ENV === "development") {
//       await delay(2000);
//     }
//     const comp = await pageCompResolver();
//     done();
//     return comp;
//   };
// }

// export default [
//   {
//     name: "Home",
//     path: "/",
//     component: getPageComponent(() =>
//       import(/* webpackChunkName: "home" */ "@/views/Home")
//     ),
//     meta: {
//       title: "首页",
//     },
//   },
//   {
//     name: "About",
//     path: "/about",
//     component: getPageComponent(() =>
//       import(/* webpackChunkName: "about" */ "@/views/About")
//     ),
//     meta: {
//       title: "关于我",
//     },
//   },
//   {
//     name: "Blog",
//     path: "/article",
//     component: getPageComponent(() =>
//       import(/* webpackChunkName: "blog" */ "@/views/Blog")
//     ),
//     meta: {
//       title: "文章",
//     },
//   },
//   {
//     name: "CategoryBlog",
//     path: "/article/cate/:categoryId",
//     component: getPageComponent(() =>
//       import(/* webpackChunkName: "blog" */ "@/views/Blog")
//     ),
//     meta: {
//       title: "文章",
//     },
//   },
//   {
//     name: "BlogDetail",
//     path: "/article/:id",
//     component: getPageComponent(() =>
//       import(/* webpackChunkName: "blogdetail" */ "@/views/Blog/Detail")
//     ),
//     meta: {
//       title: "文章详情",
//     },
//   },
//   {
//     name: "Project",
//     path: "/project",
//     component: getPageComponent(() =>
//       import(/* webpackChunkName: "project" */ "@/views/Project")
//     ),
//     meta: {
//       title: "项目&效果",
//     },
//   },
//   {
//     name: "Message",
//     path: "/message",
//     component: getPageComponent(() =>
//       import(/* webpackChunkName: "message" */ "@/views/Message")
//     ),
//     meta: {
//       title: "留言板",
//     },
//   },
// ];
