<template>
  <div class="app-container">
    <Layout>
      <template #left>
        <div class="aside">
          <SiteAside />
        </div>
      </template>
      <template #default>
        <RouterView />
      </template>
    </Layout>
    <ToTop />
  </div>
</template>

<script>
import Icon from "./components/Icon.vue"
import Layout from "./components/Layout";
import SiteAside from "./components/SiteAside";
import ToTop from "./components/ToTop"
export default {
  components: {
    Icon,
    Layout,
    SiteAside,
    ToTop,
  },
};
// const AsyncComponent = () => {
//   return new Promise((resolve) => {
//     setTimeout(async () => {
//       const MyComp = await import("./MyComp");
//       resolve(MyComp);
//     }, 3000);
//   });
// };异步组件，在components中注册后也可使用。
</script>

<style lang="less" scoped>
@import "~@/styles/mixin.less";
.app-container {
  .self-fill(fixed);
   overflow-y: hidden;
}
.aside {
  width: 250px;
  // height: 100%;
}
</style>


