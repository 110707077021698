import eventBus from "../eventBus"
import {debounce} from "../utils/"
//懒加载适用于加载不同图片.
//如果要使用加载图请用导入的方式，不然打包后路径会变。
import loading from "@/assets/default.gif"

let imgs =[]

function setImg(img){
   //img.dom.src实际用于目前显示的值，img.src正确的v-lazy后面的图片值。
   if(!img.dom){
      return
   }
   img.dom.src =loading; 
   const clientHeight =document.documentElement.clientHeight;
   const rect=img.dom.getBoundingClientRect();//在视口中的位置
   const height =rect.height || 150;
   //满足条件就在视口范围内
   if(rect.top>=-height&&rect.top<=clientHeight){
     const tempImg =new Image()
     tempImg.omload = function(){
        img.dom.src =img.src;
     }
     tempImg.src=img.src
     imgs =imgs.filter((i)=> i !== img )
   }
}
function setIamges(){
   for (const img in imgs){
       setImg(img);
   }
}

function handleScroll(dom){
    setIamges();
}

eventBus.$on("mainScroll",debounce(handleScroll,50))
export default{
   inserted(el,bindings){
     const img={
        dom:el,
        src:bindings.value,//拿到v-lazy后面的值
     }
     imgs.push(img);
     setImg(img);
   },
   unbind(el){
    imgs=imgs.filter(img=>img.dom !==el)
   }
}