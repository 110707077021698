<template>
  <Layout>
    <div ref="mainContainer" class="main-container" v-loading="isLoading">
      <BlogDetail :blog="data" v-if="data" />
      <BlogComment v-if="!isLoading" />
    </div>
    <template #right>
      <div class="right-container" v-loading="isLoading">
        <BlogTOC :toc="data.toc" v-if="data" />
      </div>
    </template>
  </Layout>
</template>

<script>
import fetchData from '../../mixins/fetchData';
import {getBlog} from "../../api/blog"
import Layout from "../../components/Layout.vue"
import BlogDetail from "./BlogDetail.vue";
import BlogTOC from "./BlogTOC.vue" 
import BlogComment from "./BlogComment.vue"

export default {
  mixins:[fetchData(null)],
  components:{
    Layout,
    BlogDetail,
    BlogTOC,
    BlogComment,
  },
  methods:{
    async fetchData(){
        return await getBlog(this.$route.params.id);
    },
    handleScroll(){
     this.$bus.$emit("mainScroll",this.$refs.mainContainer)
    },
    handleSetMainScroll(scrollTop){
       this.$refs.mainContainer.scrollTop=scrollTop;
    }
  },
  mounted(){
    this.$bus.$on("setMainScroll",this.handleSetMainScroll)
    this.$refs.mainContainer.addEventListener("scroll",this.handleScroll)
  },
  beforeDestroyed(){
    this.$bus.$emit("mainScroll")
    this.$refs.mainContainer.removeEventListener("scroll",this.handleScroll)
    this.$bus.$off("setMainScroll",this.handleSetMainScroll)
  },
  updated(){
    const hash = location.hash;
    location.hash ="";
    setTimeout(()=>{location.hash=hash},50)
  }
};
</script>

<style scoped lang="less">
.main-container{
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  width: 100%;
  scroll-behavior: smooth;
}
.right-container {
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  position: relative;
  padding: 20px;
}
</style>
