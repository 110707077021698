// import "./mock";
import Vue from 'vue'
import App from './App.vue'
import "./styles/global.less"
import router from "./router";
import showMessage from "./utils/showMessage.js";
import "./eventBus"
import store from "./store"
store.dispatch("setting/fetchSetting");
Vue.prototype.$showMessage = showMessage;
Vue.prototype.$store=store;

import vloading from "./directives/loading";
import vlazy from "./directives/lazy";
Vue.directive("loading",vloading);
Vue.directive("lazy",vlazy);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
