var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],ref:"container",staticClass:"blog-list-container"},[(_vm.data)?_c('ul',_vm._l((_vm.data.rows),function(item){return _c('li',{key:item.id},[(item.thumb)?_c('div',{staticClass:"thumb"},[_c('RouterLink',{attrs:{"to":{
            name: 'BlogDetail',
            params: {
              id: item._id,
            },
          }}},[_c('img',{attrs:{"src":item.thumb,"alt":item.title,"title":item.title}})])],1):_vm._e(),_c('div',{staticClass:"main"},[_c('RouterLink',{attrs:{"to":{
            name: 'BlogDetail',
            params: {
              id: item._id,
            },
          }}},[_c('h2',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"aside"},[_c('span',[_vm._v("日期："+_vm._s(_vm.formatDate(item.createDate)))]),_c('span',[_vm._v("浏览："+_vm._s(item.scanNumber))]),_c('span',[_vm._v("评论"+_vm._s(item.commentNumber))]),_c('RouterLink',{attrs:{"to":{
              name: 'CategoryBlog',
              params: {
                categoryId: item.category._id,
              },
            }}},[_vm._v(" "+_vm._s(item.category.name)+" ")])],1),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(item.description)+" ")])],1)])}),0):_vm._e(),(_vm.data.rows.length === 0 && !_vm.isLoading)?_c('Empty'):_vm._e(),(_vm.data.total)?_c('Pager',{attrs:{"current":_vm.routeInfo.page,"limit":_vm.routeInfo.limit,"total":_vm.data.total,"visableNumber":10},on:{"pageChange":_vm.handlePageChange}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }