import { render, staticRenderFns } from "./DataForm.vue?vue&type=template&id=60185ed5&scoped=true"
import script from "./DataForm.vue?vue&type=script&lang=js"
export * from "./DataForm.vue?vue&type=script&lang=js"
import style0 from "./DataForm.vue?vue&type=style&index=0&id=60185ed5&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60185ed5",
  null
  
)

export default component.exports