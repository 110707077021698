<template>
  <ul class="contact-container">
    <li>
     <a target="_blank" :href="data.github">
        <div class="icon">
          <Icon type="github" />
        </div>
        <span>{{data.githubName}}</span>
     </a>
    </li>
    <li>
     <a @click="copyMail">
        <div class="icon">
          <Icon type="mail" />
        </div>
        <span>{{data.mail}}</span>
     </a>
    </li>
    <li>
     <a href="">
        <div class="icon">
          <Icon type="qq" />
        </div>
        <span>{{data.qq}}</span>
     </a>
     <div class="pop">
       <img :src="data.qqQrCode" alt="">
     </div>
    </li>
     <li>
     <a href="">
        <div class="icon wx">
          <Icon type="weixin" />
        </div>
        <span>{{data.weixin}}</span>
     </a>
     <div class="pop">
       <img :src="data.weixinQrCode" alt="">
     </div>
    </li>
  </ul>
</template>

<script>
import Icon from "./Icon.vue";
import { mapState } from "vuex";
   
export default {
   components:{
    Icon,
   },
   methods: {
    copyMail() {
      navigator.clipboard.writeText("862932693@qq.com")
      alert("邮箱复制成功！")
    }
   },
   computed:mapState("setting", ["data"]),
}
</script>

<style scoped lang="less">
@import "~@/styles/var.less";
.contact-container{
    padding: 20px;
    list-style: none;
    margin: 0px;
    color:@gray;
    li{
        margin: 14px 0;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        position: relative;
        &:hover{
            .pop{
                transform: scaleY(1);
                transition: 0.3s;
            }
        }
    }
    a{
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .icon{
        font-size: 26px;
        width: 36px;
        &.wx{
            font-size: 32px;
            text-indent: -3px;
        }
    }
    .pop{
        position: absolute;
        left:0;
        bottom:35px;
        padding: 10px 15px;
        background: #fff;
        border-radius: 5px;
        transform: scaleY(0);
        transform-origin: center bottom;
        img{
            width: 150px;
            height: 150px;
        }
        &::after{
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            width: 8px;
            height: 8px;
            bottom:-4px ;
            background: #fff;
        }
    }
}
</style>