<template>
  <i class="iconfont icon-container" :class="fontClass"></i>
</template>

<script>
const classMap = {
  home: "icon-home",
  success: "icon-zhengque",
  error: "icon-cuowu",
  close: "icon-gaunbi",
  warn: "icon-jinggao",
  info: "icon-xinxi",
  blog: "icon-blog",
  code: "icon-code",
  about: "icon-about",
  weixin: "icon-weixin",
  mail: "icon-mail",
  github: "icon-github",
  qq: "icon-QQ",
  arrowUp: "icon-arrow-up",
  arrowDown: "icon-arrowdown",
  empty: "iconempty",
  chat: "icon-liuyan",
};
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    // 图标类样式
    
    fontClass() {
      return classMap[this.type];
    },
  },
};
</script>

<style scoped>
@import "https://at.alicdn.com/t/c/font_4672675_bqti9eml8m.css";
.iconfont {
  color: inherit;
  font-size: inherit;
}
</style>
