//获取远程数据方法fetchData由具体组件提供。该js只负责调用
//公共获取远程数据代码（抽离出来公共部分）
//vue3里有更好的替代方案，vue2没有。
export default function (defaultDataValue = null) {
  return {
    data() {
      return {
        isLoading: true,
        data: defaultDataValue,
      };
    },
    async created() {

      this.data = await this.fetchData();
      this.isLoading = false;
    },
  }

} 