<template>
  <div class="site-aside-container" v-if="data">
      <Avatar class="avatar" :url="data?.avatar"/>
      <h1 class="name">{{ data?.siteTitle }}</h1>
      <Menu />
      <Contact />
      <p class="footer">
       {{ data?.icp }}
      </p>
  </div>
</template>

<script>
import Contact from './Contact.vue'
import Menu from "./Menu.vue"
import Avatar from "./Avatar.vue"
import { mapState } from "vuex";
export default {
  components: { 
    Contact, 
    Menu,
    Avatar,
  },
  computed:mapState("setting", ["data"]),
}
</script>

<style scoped lang="less">
@import "~@/styles/var.less";
.site-aside-container{
   width: 100%;
   height: 100%;
   background: @dark;
   padding: 20px 0;
   box-sizing: border-box;
   overflow-x:hidden ;
   overflow-y:auto ;
}
.avatar{
  margin: 0 auto;
  height: 170px;
  width: 170px;
}
.footer{
  text-align: center;
  font-size: 12px;

}
.name{
  text-align: center;
  font-size: 1.2em;
  color: #fff;
}
</style>