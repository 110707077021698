<template>
  <div class="empty-container">
    <i class="iconfont icon-queshengye_zanwushuju"></i>
    <p class="text">{{text}}</p>
  </div>
</template>

<script>
export default {
   props:{
    text:{
        type:String,
        default:"无数据",
    }
   }
}
</script>

<style scoped>
@import "//at.alicdn.com/t/c/font_4671392_51ikn82r7bp.css";
.iconfont {
  color: inherit;
  font-size: inherit;
}
.empty-container {
    display: flex;
    flex-direction:column;
    text-align: center;
    height: 200px;
    width: 200px;
    transform: translate(-50%,-50%);
    position: relative;
    top: 50%;
    left:50%;
}
.iconfont{
    height: 180px;
    width: 200px;
    line-height: 180px;
    font-size: 120px;
}
.text{
    height: 20px;
    width: 200px;
    font-size: 15px;
    transform: translate(0,-150%);
    text-align: center;
}
</style>